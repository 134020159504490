.sn-notifications-container {
  /* colors */
  --sn-success-color: rgb(111, 207, 151);
  --sn-success-progress-color: rgb(84, 170, 120);
  --sn-error-color: rgb(235, 87, 87);
  --sn-error-progress-color: rgb(192, 69, 69);
  --sn-warning-color: rgb(242, 201, 76);
  --sn-warning-progress-color: rgb(196, 166, 79);
  --sn-info-color: rgb(81, 205, 243);
  --sn-info-progress-color: rgb(84, 169, 196);

  /* container */
  --sn-notifications-gap: 20px; /* controlled by js */
  --sn-notifications-padding: 20px; /* controlled by js */

  /* notify */
  --sn-notify-width: 320px;
  --sn-notify-radius: 6px;
  --sn-notify-transition-timing-function: ease;
  --sn-notify-transition-duration: 500ms; /* controlled by js */
  --sn-notify-autoclose-timeout: 30000ms; /* controlled by js */
  --sn-notify-padding: 0.75em;
  --sn-notify-icon-size: 2em;
  --sn-notify-close-icon-size: 1em;
  --sn-notify-border-size: 1px;
  --sn-notify-border-color: transparent;
  --sn-notify-border-style: solid;
  --sn-notify-progress-color: rgb(51, 51, 51);
  --sn-notify-close-icon-color: rgb(51, 51, 51);
  --sn-notify-title-color: rgb(51, 51, 51);
  --sn-notify-text-color: rgb(77, 77, 77);
  --sn-notify-shadow-color: transparent;
  --sn-notify-progress-height: 5px;

  pointer-events: none;
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  grid-auto-rows: min-content;
  align-content: flex-start;
  grid-gap: var(--sn-notifications-gap);
  gap: var(--sn-notifications-gap);
  padding: var(--sn-notifications-padding);
}

  .sn-notifications-container.sn-is-x-center {
    justify-content: center;
  }

  .sn-notifications-container.sn-is-y-center {
    align-content: center;
    justify-content: flex-end;
  }

  .sn-notifications-container.sn-is-center {
    justify-content: center;
    align-content: center;
  }

  .sn-notifications-container.sn-is-left {
    justify-content: flex-start;
  }

  .sn-notifications-container.sn-is-right {
    justify-content: flex-end;
  }

  .sn-notifications-container.sn-is-top {
    align-content: flex-start;
  }

  .sn-notifications-container.sn-is-bottom {
    align-content: flex-end;
  }

.sn-notify {
  pointer-events: auto;
  transition-timing-function: var(--sn-notify-transition-timing-function);
  transition-duration: var(--sn-notify-transition-duration);
  transition-property: transform, opacity;
  border-radius: var(--sn-notify-radius);
  background-color: var(--sn-notify-background-color, transparent);
  border: var(--sn-notify-border-size) var(--sn-notify-border-style) var(--sn-notify-border-color);
  padding: var(--sn-notify-padding);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: var(--sn-notify-width);
}

.sn-notify-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: var(--sn-notify-icon-size);
  height: var(--sn-notify-icon-size);
  margin-right: 0.75em;
  color: var(--sn-notify-icon-color);
}

.sn-notify-close {
  position: absolute;
  right: 0.75em;
  top: 0.75em;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--sn-notify-close-icon-size);
  width: var(--sn-notify-close-icon-size);
  color: var(--sn-notify-close-icon-color);
}

.sn-notify-title {
  font-size: 1rem;
  font-weight: 600;
  padding-right: calc(var(--sn-notify-padding) + var(--sn-notify-close-icon-size));
  color: var(--sn-notify-title-color);
}

.sn-notify-text {
  font-size: 0.875rem;
  margin-top: 0.25rem;
  color: var(--sn-notify-text-color);
}

.sn-notify-outline {
  --sn-notify-background-color: rgb(255, 255, 255);
  --sn-notify-border-color: var(--sn-success-color);
  box-shadow: 0 2px 26px var(--sn-notify-shadow-color);
}

.sn-notify-outline.sn-notify-success {
    --sn-notify-border-color: var(--sn-success-color);
    --sn-notify-icon-color: var(--sn-success-color);
    --sn-notify-shadow-color: rgba(82, 215, 0, 0.1);
  }

.sn-notify-outline.sn-notify-error {
    --sn-notify-border-color: var(--sn-error-color);
    --sn-notify-icon-color: var(--sn-error-color);
    --sn-notify-shadow-color: rgba(215, 0, 0, 0.1);
  }

.sn-notify-outline.sn-notify-warning {
    --sn-notify-border-color: var(--sn-warning-color);
    --sn-notify-icon-color: var(--sn-warning-color);
    --sn-notify-shadow-color: rgba(242, 201, 76, 0.1);
  }

.sn-notify-outline.sn-notify-info {
    --sn-notify-border-color: var(--sn-info-color);
    --sn-notify-icon-color: var(--sn-info-color);
    --sn-notify-shadow-color: rgba(84, 175, 202, 0.1);
  }

.sn-notify-outline .sn-notify-title {
    --sn-notify-title-color: rgb(51, 51, 51);
  }

.sn-notify-outline .sn-notify-text {
    --sn-notify-text-color: rgb(77, 77, 77);
  }

.sn-notify-outline .sn-notify-close {
    --sn-notify-close-icon-color: rgb(130, 130, 130);
  }

.sn-notify-filled {
  --sn-notify-title-color: rgb(51, 51, 51);
  --sn-notify-text-color: rgb(51, 51, 51);
  --sn-notify-icon-color: rgb(51, 51, 51);
  --sn-notify-close-icon-color: rgb(51, 51, 51);
}

.sn-notify-filled.sn-notify-success {
    --sn-notify-background-color: var(--sn-success-color);
  }

.sn-notify-filled.sn-notify-error {
    --sn-notify-background-color: var(--sn-error-color);
  }

.sn-notify-filled.sn-notify-warning {
    --sn-notify-background-color: var(--sn-warning-color);
  }

.sn-notify-filled.sn-notify-info {
    --sn-notify-background-color: var(--sn-info-color);
  }

.sn-notify-fade {
  opacity: 0;
  will-change: opacity;
}

.sn-notify-fade-in {
  opacity: 1;
}

.sn-notify-slide {
  opacity: 0;
  will-change: opacity, transform;
  transform: translate3d(var(--sn-notify-x), var(--sn-notify-y), 0);
}

.sn-is-left .sn-notify-slide {
    --sn-notify-x: -100%;
    --sn-notify-y: 0;
}

.sn-is-right .sn-notify-slide, .sn-is-y-center:not(.sn-is-left) .sn-notify-slide {
    --sn-notify-x: 100%;
    --sn-notify-y: 0;
}

.sn-is-top.sn-is-x-center .sn-notify-slide, .sn-is-x-center .sn-notify-slide {
    --sn-notify-y: -100%;
    --sn-notify-x: 0;
}

.sn-is-bottom.sn-is-x-center .sn-notify-slide {
    --sn-notify-y: 100%;
    --sn-notify-x: 0;
}

.sn-is-bottom.sn-is-x-center .sn-notify-slide {
    --sn-notify-y: 100%;
    --sn-notify-x: 0;
}

.sn-notify-slide-in {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.sn-notify-autoclose {
  padding-bottom: calc(var(--sn-notify-padding) + var(--sn-notify-progress-height));
}

.sn-notify-autoclose.sn-notify-success {
    --sn-notify-progress-color: var(--sn-success-progress-color);
  }

.sn-notify-autoclose.sn-notify-error {
    --sn-notify-progress-color: var(--sn-error-progress-color);
  }

.sn-notify-autoclose.sn-notify-warning {
    --sn-notify-progress-color: var(--sn-warning-progress-color);
  }

.sn-notify-autoclose.sn-notify-info {
    --sn-notify-progress-color: var(--sn-info-progress-color);
  }

.sn-notify-autoclose::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  will-change: width;
  width: 100%;
  height: var(--sn-notify-progress-height);
  border-radius: 0 999px 999px 0;
  background-color: var(--sn-notify-progress-color);
  animation: progress var(--sn-notify-autoclose-timeout) linear forwards;
}

.sn-notify-content {
  padding-right: calc(var(--sn-notify-close-icon-size) + 2px);
}

@keyframes progress {
  to {
    width: 0%;
  }
}
